import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
//Note : Uncomment the firebase import if we are going live
// import firebaseConfig from "./firebase.config";
import firebaseConfig from "./firebase-dev.config";

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default analytics;
