import {
  Box,
  Button,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IsMobile } from "../../../utils/deviceType";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as Bag } from "../../../assets/images/icons/bag.svg";
import { ReactComponent as NewProject } from "../../../assets/images/icons/newProject.svg";
import { ReactComponent as Name } from "../../../assets/images/icons/name.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  setLastCompletedStep,
  setContextStepInfo,
} from "../../../reducers/treeMapSlice";

const styles = {
  headerTxt: {
    fontSize: "28px",
    fontFamily: "ClashDisplay-Medium",
  },
  headerBTN: {
    width: "45%",
    height: "44px",
    color: "white",
    textTransform: "none",
    borderRadius: "3px",
    fontSize: 15,
    fontFamily: "ClashDisplay-Medium",
  },
  container: {
    width: "40%",
    margin: "0 auto",
    marginTop: "10%",
    padding: "30px",
    borderRadius: "5px",
    border: "0.5px solid #c1cdd5",
    boxSizing: "border-box",
    boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
    backgroundColor: "white",
  },
  footerBTN: {
    width: "180px",
    marginLeft: "calc(70% - 180px)",
    marginTop: "20px",
    height: "44px",
    color: "white",
    textTransform: "none",
    borderRadius: 2,
    fontSize: 15,
    fontFamily: "ClashDisplay-Medium",
  },
};

export const ContextStep = ({ setTreeInfoChanged, setCurrentStepNumber }) => {
  const {
    control,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [requestType, setRequestType] = useState(1);
  const dispatch = useDispatch();
  const isMobile = IsMobile();
  const contextStepInfo = useSelector((state) => state.treeMap.contextStepInfo);
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const lastCompletedStep = useSelector(
    (state) => state.treeMap.lastCompletedStep
  );

  const onSubmit = async (data) => {
    if (JSON.stringify(data) !== JSON.stringify(contextStepInfo)) {
      dispatch(setContextStepInfo(data));
      setTreeInfoChanged(true);
      if (lastCompletedStep < 1) {
        dispatch(setLastCompletedStep(1));
      }
    } else {
      setTreeInfoChanged(false);
    }
    setCurrentStepNumber(2);
  };

  const handleFieldChange = (fieldName) => {
    setError(fieldName, ""); // Clear errors on field change
  };

  useEffect(() => {
    if (contextStepInfo) {
      setRequestType(contextStepInfo.requestType);
      Object.keys(contextStepInfo).map((fieldName) =>
        setValue(fieldName, contextStepInfo[fieldName])
      );
    }
  }, [contextStepInfo]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={styles.container}>
        <Typography sx={styles.headerTxt}>Context</Typography>
        <Controller
          control={control}
          name="requestType"
          defaultValue={1}
          render={({ field: { onChange } }) => (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                mt: 4,
              }}
            >
              <Box
                onClick={() =>
                  !eventGenerating && (setRequestType(1), onChange(1))
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...styles.headerBTN,
                  backgroundColor: requestType === 1 ? "#1D77B7" : "#ececec",
                  color: requestType === 1 ? "white" : "black",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                  },
                }}
              >
                <Bag
                  width="20px"
                  style={{ marginRight: 5 }}
                  fill={requestType === 1 ? "white" : "black"}
                />
                Role
              </Box>
              <Box
                onClick={() =>
                  !eventGenerating && (setRequestType(2), onChange(2))
                }
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  ...styles.headerBTN,
                  backgroundColor: requestType === 2 ? "#1D77B7" : "#ececec",
                  color: requestType === 2 ? "white" : "black",
                  marginLeft: "3%",
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                  },
                }}
              >
                <NewProject
                  width="20px"
                  style={{ marginRight: 5 }}
                  fill={requestType === 2 ? "white" : "black"}
                />
                Project
              </Box>
            </Box>
          )}
        />
        <Controller
          control={control}
          name="event"
          rules={{
            required: "Please fill this field",
          }}
          render={({ field }) => (
            <TextField
              label=""
              sx={{ mt: 3, mb: 2 }}
              placeholder={requestType === 1 ? "Name" : "Project Name"}
              fullWidth
              disabled={eventGenerating}
              onChange={() => {
                handleFieldChange("event");
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {requestType === 1 ? (
                      <Name width="20px" fill="#1D77B7" />
                    ) : (
                      <NewProject width="20px" fill="#1D77B7" />
                    )}
                  </InputAdornment>
                ),
              }}
              id="standard-size-normal"
              variant="standard"
              {...field}
              helperText={errors.event?.message}
              error={Boolean(errors.event)}
            />
          )}
        />
        {requestType !== 2 && (
          <Controller
            control={control}
            name="job_title"
            rules={{
              required: "Please fill this field",
            }}
            render={({ field }) => (
              <TextField
                label=""
                sx={{ mb: 2 }}
                disabled={eventGenerating}
                placeholder={"Your Role"}
                fullWidth
                onChange={() => {
                  handleFieldChange("job_title");
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Bag width="20px" fill="#1D77B7" />
                    </InputAdornment>
                  ),
                }}
                id="standard-size-normal"
                variant="standard"
                {...field}
                helperText={errors.job_title?.message}
                error={Boolean(errors.job_title)}
              />
            )}
          />
        )}
        <Controller
          control={control}
          name="context"
          rules={{
            required: "Please fill this field",
          }}
          render={({ field }) => (
            <TextField
              sx={{
                "& fieldset": { border: "1px solid #e7e7e7" },
              }}
              disabled={eventGenerating}
              inputProps={{
                style: {
                  fontFamily: "ClashDisplay-Regular",
                  fontSize: isMobile ? 12 : 16,
                },
              }}
              fullWidth
              placeholder={`Describe your company, ${requestType === 1 ? "role" : "project"}, key objectives, challenges, and any relevant background information`}
              multiline
              minRows={4}
              maxRows={6}
              onChange={() => {
                handleFieldChange("context");
              }}
              {...field}
              helperText={errors.context?.message}
              error={Boolean(errors.context)}
            />
          )}
        />
      </Box>
      <Button
        variant="contained"
        type="submit"
        sx={{
          ...styles.footerBTN,
          backgroundColor: "#1D77B7",
          "&:hover": {
            backgroundColor: "#1D77B7",
            boxShadow: "none",
          },
        }}
      >
        Next
      </Button>
    </form>
  );
};
