import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import IndentedList from "../components/IndentedList/IndentedList";
import { getAllAssessments, getEventInfo } from "../services/api-service";

const Assessment = ({ eventID, label }) => {
  const [eventRatings, setEventRatings] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [eventName, setEventName] = useState(null);
  const [originalEventRatings, setOriginalEventRatings] = useState([]);

  useEffect(() => {
    try {
      getAllAssessments({ eventID, label }).then((res) => {
        if (res?.status === 200) {
          setEventData(res.data?.result?.node);
          setEventName(res.data?.result?.title);
          setEventRatings(res.data?.result?.assessments);
        }
      });
    } catch (error) {
      console.log(">>error", error);
    }
  }, [eventID, label]);

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      getEventInfo(eventID).then((res) => {
        if (res?.status === 200) {
          setOriginalEventRatings(res?.data?.result);
        }
      });
    }
  }, []);

  return (
    <Box sx={{ height: window.innerHeight }}>
      {eventData && eventName ? (
        <>
          <Box className="header">
            <Box
              sx={{
                display: "flex",
                fontFamily: "ClashDisplay-Medium",
                height: "100%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {eventName}
            </Box>
          </Box>
          <Box sx={{ height: "calc(100% - 61px)", position: "relative" }}>
            <IndentedList
              isAssesmentLink
              data={eventData?.Pitfalls ?? eventData?.children}
              eventRatings={eventRatings}
              originalEventRatings={originalEventRatings}
            />
          </Box>
        </>
      ) : null}
    </Box>
  );
};
export default Assessment;
