import {
  Box,
  Button,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ReactComponent as BrandLogo } from "../assets/images/logo.svg";
import isEmail from "is-email";
import Loader from "../components/Loader/Loader";
import { useAuth } from "../services/auth";
import { IsMobile } from "../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";
import { GoogleLogin } from "@react-oauth/google";

export default function SignUp() {
  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: null,
      password: null,
      confPassword: null,
    },
  });
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isMobile = IsMobile();
  const analytics = getAnalytics();
  const { signUp, signInWithGoogle, setAuthLoading, authLoading } = useAuth();

  useEffect(() => {
    logEvent(analytics, "Signup page loaded!");
  }, []);

  const onSubmit = async (data) => {
    const { email, password } = data;
    setErrorMessage(null);

    const result = await signUp({ email, password });

    if (result) {
      setAuthLoading(false);
      if (result?.response?.data?.error.includes("user already exists")) {
        setErrorMessage("This email already exists");
      } else setErrorMessage("Unable to process");
    }
  };

  const handleSignInWithGoogle = async (accessToken) => {
    try {
      const result = await signInWithGoogle(accessToken);
    } catch (error) {
      console.error("Google Sign-In Error:", error.message);
    }
  };

  return (
    <Box>
      {authLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: isMobile ? "8%" : "4rem",
            marginBottom: "2rem",
          }}
        >
          <BrandLogo />
          <Typography
            sx={{
              color: "#2d333a",
              margin: `${isMobile ? "20%" : "120px"}  0px 24px`,
              fontFamily: "ClashDisplay-Medium",
              fontSize: 30,
              textAlign: "center",
            }}
          >
            Create your account
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "320px",
              maxWidth: "80vw",
            }}
          >
            <Controller
              control={control}
              name="email"
              rules={{
                required: "Enter an email",
                validate: {
                  validateEmail: (email) =>
                    isEmail(email) || "Enter a valid email",
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email Address"
                  helperText={errors?.email?.message}
                  error={Boolean(errors.email)}
                  type="email"
                  sx={{
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23) !important",
                      borderLeft: "3px solid #1D77B7 !important",
                    },
                    "& .Mui-focused fieldset": {
                      border: "1px solid #1D77B7 !important",
                    },
                    "& input": {
                      height: "1.5em",
                      padding: "14px",
                    },
                  }}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{
                required: "Enter a password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              }}
              render={({ field }) => (
                <TextField
                  label="Password"
                  {...field}
                  helperText={errors?.password?.message}
                  error={Boolean(errors.password)}
                  sx={{
                    marginTop: "12px",
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23) !important",
                      borderLeft: "3px solid #1D77B7 !important",
                    },
                    "& .Mui-focused fieldset": {
                      border: "1px solid #1D77B7 !important",
                    },
                    "& input": {
                      height: "1.5em",
                      padding: "14px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          style={{ paddingRight: "10px" }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showPassword ? "text" : "password"}
                />
              )}
            />

            <Controller
              control={control}
              name="confPassword"
              rules={{
                required: "Enter a confirm password",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
                validate: {
                  matchPassword: (value) =>
                    value === getValues("password") || "Passwords do not match",
                },
              }}
              render={({ field }) => (
                <TextField
                  label="Confirm Password"
                  {...field}
                  helperText={errors?.confPassword?.message}
                  error={Boolean(errors.confPassword)}
                  sx={{
                    marginTop: "12px",
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23) !important",
                      borderLeft: "3px solid #1D77B7 !important",
                    },
                    "& .Mui-focused fieldset": {
                      border: "1px solid #1D77B7 !important",
                    },
                    "& input": {
                      height: "1.5em",
                      padding: "14px",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          edge="end"
                          style={{ paddingRight: "10px" }}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                />
              )}
            />
            <Box>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  mt: "26px",
                  height: 45,
                  borderRadius: "6px",
                  fontSize: "16px",
                  fontFamily: "sans-serif",
                  textTransform: "none",
                  width: "100%",
                  backgroundColor: "#1D77B7",
                  boxShadow: "none",
                  "&:hover": {
                    background: "#0f639e",
                    boxShadow: "none",
                  },
                }}
              >
                Register
              </Button>
              <Box
                sx={{
                  textAlign: "center",
                  marginTop: 1,
                }}
              >
                <Typography
                  sx={{
                    alignSelf: "center",
                    fontSize: "14px",
                    marginTop: "14px",
                    color: "#2d333a",
                  }}
                >
                  Already have an account?{" "}
                  <NavLink
                    to="/signin"
                    style={{
                      textDecoration: "none",
                      color: "#1D77B7",
                      cursor: "pointer",
                    }}
                  >
                    Log in
                  </NavLink>
                </Typography>
              </Box>
            </Box>
            <Box sx={{ marginTop: 2, textAlign: "center" }}>
              <Typography
                sx={{
                  visibility: errorMessage ? "visible" : "hidden",
                }}
                color="error"
              >
                {errorMessage}
              </Typography>
            </Box>
            <div className="or">
              <span>Or</span>
            </div>
          </form>
          <GoogleLogin
            onSuccess={(res) => handleSignInWithGoogle(res.credential)}
            onError={(err) => setErrorMessage(err)}
            containerProps={{
              style: {
                marginTop: "10px",
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
}
