import { isSafariBrowser } from "../../utils/constants";

export const CollapseIcon = ({ collapsed, firstChild }) => (
  <svg
    width="11"
    height="11"
    style={{
      marginRight: 7,
      marginTop: firstChild ? 5 : 4,
      transition: !isSafariBrowser && "all 0.35s",
      transform: !collapsed && "rotate(90deg)",
      flexShrink: 1,
    }}
    viewBox="0 0 6 8"
    fill="none"
  >
    <path d="M1.25 0.75L4.75 4L1.25 7.25" stroke="white" strokeWidth={1.5} />
  </svg>
);
