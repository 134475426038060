import { useEffect, useState, useRef } from "react";
import {
  Box,
  Tooltip,
  IconButton,
  Pagination,
  Skeleton,
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useSearchParams } from "react-router-dom";
import deleteIcon from "../../assets/images/icons/deleteIcon.svg";
import editIcon from "../../assets/images/icons/edit.svg";
import menuIcon from "../../assets/images/icons/menu.svg";
import feedback from "../../assets/images/icons/feedback.svg";
import {
  getTreeHistory,
  setIsConfirmDeleteModalOpen,
  setSelectedUpdateTreeId,
} from "../../reducers/treeMapSlice";
import roleIcon from "../../assets/images/icons/role.svg";
import situationIcon from "../../assets/images/icons/situation.svg";
import { getEvent } from "../../reducers/treeMapSlice";
import "./EventHistory.css";
import { IsMobile } from "../../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function EventHistory({ setSidebarOpened, setShowManageEvent }) {
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const eventHistory = useSelector((state) => state.treeMap.history);
  const selectedEventId = useSelector((state) => state.treeMap.selectedTreeId);
  const userData = useSelector((state) => state.treeMap.userData);
  const isEventHistoryLoading = useSelector(
    (state) => state.treeMap.isTreeHistoryLoading
  );
  const eventLoading = useSelector((state) => state.treeMap.loading);
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const totalPages = useSelector((state) => state.treeMap.totalPages);
  const eventId = searchParams.get("eventId"),
    pageNumber = parseInt(searchParams.get("page")) || 1;
  const isMobile = IsMobile();
  const analytics = getAnalytics();

  useEffect(() => {
    if (pageNumber != 1) {
      dispatch(getTreeHistory(pageNumber));
    } else dispatch(getTreeHistory(1));
  }, [pageNumber]);

  useEffect(() => {
    if (searchParams.has("code")) {
      const state = JSON.parse(searchParams.get("state"));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        eventId: state.eventId,
        page: state.page,
      });
      selectEvent(state.eventId);
    } else {
      if (eventHistory.length > 0 && eventId && pageNumber) {
        if (!selectedEventId) {
          selectEvent(eventId);
        } else {
          selectEvent(selectedEventId);
        }
      } else if (eventHistory.length > 0 && (!eventId || !pageNumber)) {
        selectEvent(eventHistory[0]._id);
        setSearchParams({
          ...Object.fromEntries(searchParams),
          eventId: eventHistory[0]._id,
          page: 1,
        });
      }
    }
  }, [selectedEventId, eventHistory]);

  const selectEvent = (key) => {
    isMobile && setSidebarOpened(false);
    setShowManageEvent(false);
    dispatch(setSelectedUpdateTreeId(""));
    if (!eventLoading && !eventGenerating) {
      logEvent(analytics, `Event Clicked From Event History!`, {
        user: userData._id,
      });
      dispatch(getEvent(key));
      setSearchParams({
        ...Object.fromEntries(searchParams),
        eventId: key,
      });
    }
  };

  const toggleOpenConfirmationModal = (eventId, eventName) => {
    dispatch(
      setIsConfirmDeleteModalOpen({
        type: "event",
        id: eventId,
        name: eventName,
      })
    );
  };

  const LoadingCard = ({ index }) => (
    <Box key={index} className="loadingCardContainer">
      <Skeleton
        variant="text"
        width="90%"
        height="20px"
        sx={{ backgroundColor: "#e7e7e7", mt: "10px" }}
        animation="wave"
      />
      <Skeleton
        variant="rectangular"
        width="90%"
        height="30px"
        sx={{ backgroundColor: "#e7e7e7", mt: 1 }}
        animation="wave"
      />
    </Box>
  );

  const EventCard = ({ event, i }) => {
    const [optionsOpened, setOptionsOpened] = useState(false);
    const dropdownRef = useRef(null);
    return (
      <Box
        key={i}
        className={
          selectedEventId === event._id || (selectedEventId === "" && i === 0)
            ? "event-card selected-card"
            : "event-card"
        }
      >
        <img
          src={event.requestType === 1 ? roleIcon : situationIcon}
          alt="Logo"
          width={event.requestType === 1 ? "25" : "16"}
          height={event.requestType === 1 ? "25" : "16"}
          style={{
            cursor: "pointer",
          }}
          onClick={() => selectEvent(event._id)}
        />
        <Box onClick={() => selectEvent(event._id)} className="card-detail">
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            title={
              event?.requestType == 1 ? event.employee_name : event.event_name
            }
            arrow
          >
            <Box className="event-header">
              {event?.requestType == 1 ? event.employee_name : event.event_name}
            </Box>
          </Tooltip>
          <Tooltip
            enterDelay={1000}
            enterNextDelay={1000}
            title={event?.job_title ? event.job_title : event.context}
            arrow
          >
            <Box className="event-description">
              {event?.job_title ? event.job_title : event.context}
            </Box>
          </Tooltip>
        </Box>
        <Box>
          <Box
            className="menuIcon"
            sx={{
              visibility: (isMobile || optionsOpened) && "visible",
              backgroundColor:
                !isMobile && optionsOpened && "rgb(250, 250, 250)",
            }}
            onClick={() => setOptionsOpened(true)}
            ref={dropdownRef}
          >
            <img
              src={menuIcon}
              alt="Menu"
              width="16"
              height="16"
              style={{
                padding: 0,
              }}
            />
          </Box>
          <Popper
            open={optionsOpened}
            anchorEl={dropdownRef.current}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper>
                  <ClickAwayListener
                    onClickAway={() => setOptionsOpened(false)}
                  >
                    <Box className="dropdown-content">
                      <Box
                        className="dropdown-item"
                        onClick={() => {
                          setShowManageEvent(
                            event.requestType === 1 ? "role" : "situation"
                          );
                          setSidebarOpened(false);
                          dispatch(setSelectedUpdateTreeId(event._id));
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          width="16"
                          height="16"
                          style={{
                            padding: 0,
                            marginRight: 5,
                          }}
                        />
                        Edit
                      </Box>
                      <Box
                        className="dropdown-item"
                        onClick={() =>
                          toggleOpenConfirmationModal(event._id, event.title)
                        }
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          width="16"
                          height="16"
                          style={{
                            fill: "#585858",
                            padding: 0,
                            marginRight: 5,
                          }}
                        />
                        Delete
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="event-sidebar">
      <Box
        className="event-sidebar-title"
        sx={{ paddingLeft: isMobile ? "6px" : "12px" }}
      >
        {isMobile && (
          <IconButton onClick={() => setSidebarOpened(false)}>
            <ArrowBackIosIcon className="back-icon" fontSize="small" />
          </IconButton>
        )}
        <Box>Events History</Box>
      </Box>
      <Box
        className="event-sidebar-inner"
        sx={{
          paddingLeft: isMobile ? "16px" : "12px",
          height: `calc(100% - ${totalPages > 1 ? "160px" : "120px"})`,
        }}
      >
        {isEventHistoryLoading ? (
          Array(4)
            .fill()
            .map((_, index) => <LoadingCard key={index} index={index} />)
        ) : eventHistory?.length ? (
          eventHistory?.map((event, i) => (
            <EventCard key={i} event={event} i={i} />
          ))
        ) : (
          <Box className="event-sidebar-sub-title"> No Results </Box>
        )}
      </Box>
      {totalPages > 1 && (
        <Pagination
          style={{ alignSelf: "center", marginTop: 15 }}
          page={pageNumber || 1}
          size="small"
          shape="rounded"
          count={totalPages}
          color="primary"
          onChange={(e, val) =>
            setSearchParams({ ...Object.fromEntries(searchParams), page: val })
          }
        />
      )}
      <NavLink
        to="https://antethink.nolt.io/newest"
        className="feedback"
        target="_blank"
      >
        <img
          src={feedback}
          alt="Feedback"
          width="16"
          height="16"
          style={{
            padding: 0,
            marginRight: "7px",
            cursor: "pointer",
          }}
        />
        Feedback
      </NavLink>
    </Box>
  );
}
