import { Box } from "@mui/material";
import { ReactComponent as BrandLogo } from "../../assets/images/logo.svg";
import { Steps } from "./Steps";

export const Sidebar = ({
  setCreatedEvent,
  currentStepNumber,
  setCurrentStepNumber,
  setTreeInfoChanged,
}) => {
  return (
    <Box
      height="100%"
      borderRight="solid 1px #e7e7e7"
      width="20%"
      maxWidth="240px"
      display="flex"
      flexDirection="column"
      alignItems="start"
      p="40px 20px"
    >
      <BrandLogo
        onClick={() => setCreatedEvent(true)}
        style={{
          height: 27,
          cursor: "pointer",
          alignSelf: "center",
          marginBottom: "30%",
        }}
      />
      <Steps
        currentStepNumber={currentStepNumber}
        setCurrentStepNumber={setCurrentStepNumber}
        setTreeInfoChanged={setTreeInfoChanged}
      />
    </Box>
  );
};
