import { Box } from "@mui/material";
import { ReactComponent as Note } from "../../assets/images/icons/note.svg";
import { ReactComponent as Tree } from "../../assets/images/icons/tree.svg";
import { ReactComponent as Rocket } from "../../assets/images/icons/rocket.svg";
import { StepItem } from "./StepItem";

export const Steps = ({
  currentStepNumber,
  setTreeInfoChanged,
  setCurrentStepNumber,
}) => {
  const steps = [
    {
      title: "Context",
      icon: (color) => <Note stroke={color} width="60%" />,
      description:
        "Provide key details about your role, project, and objectives. This context enables our AI to generate tailored, high-value outputs.",
    },
    {
      title: "Inversion Tree",
      icon: (color) => <Tree fill={color} width="60%" />,
      description:
        "Review the generated risk factors. Remove irrelevant items and highlight critical concerns to refine your strategic focus.",
    },
    {
      title: "KPIs",
      icon: (color) => <Rocket fill={color} width="60%" />,
      description:
        "Evaluate the suggested performance indicators. Select those most aligned with your goals to effectively track progress and mitigate risks.",
    },
  ];

  return (
    <Box>
      {steps.map((elm, index) => (
        <StepItem
          {...elm}
          key={index}
          stepIndex={index + 1}
          currentStepNumber={currentStepNumber}
          setCurrentStepNumber={setCurrentStepNumber}
          setTreeInfoChanged={setTreeInfoChanged}
        />
      ))}
    </Box>
  );
};
