import React from "react";
import { Dialog, DialogTitle, DialogContent, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../assets/images/icons/deleteIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/images/icons/copy.svg";
import { deleteShareLink, notify } from "../../reducers/treeMapSlice";

export default function ShareLinksModal({
  shareLinks,
  dispatch,
  eventId,
  open,
  setOpen,
}) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      sx={{
        textAlign: "center",
        backdropFilter: "blur(4px)",
        "& .MuiPaper-root": {
          borderRadius: "20px",
          padding: "0px 30px",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: "ClashDisplay-medium",
          padding: "15px 20px 10px",
          fontSize: "1.5rem",
        }}
      >
        Share Links
      </DialogTitle>
      <DialogContent sx={{ minHeight: "5vh", maxHeight: "60vh", mt: "1vh" }}>
        {shareLinks.map((label) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              minWidth: "20vw",
            }}
          >
            <NavLink
              to={`${window.location.origin}/share?eventID=${eventId}&label=${label}`}
              target="_blank"
              style={{
                textAlign: "start",
                marginBottom: 15,
              }}
            >
              {`${label}`}
            </NavLink>
            <div style={{ display: "flex" }}>
              <CopyIcon
                style={{
                  marginLeft: 5,
                  marginTop: 2,
                  cursor: "pointer",
                  width: "18px",
                  height: "18px",
                }}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/share?eventID=${eventId}&label=${label}`
                  );
                  notify("Event link copied to clipboard", "success");
                }}
              />
              <DeleteIcon
                style={{
                  marginLeft: 5,
                  cursor: "pointer",
                  width: "20px",
                  height: "20px",
                  fill: "#585858",
                }}
                onClick={() => dispatch(deleteShareLink(label))}
              />
            </div>
          </Box>
        ))}
      </DialogContent>
    </Dialog>
  );
}
