import { Box } from "@mui/material";
import { Sidebar } from "./Sidebar";
import { useState } from "react";
import { ContextStep } from "./Steps/ContextStep";
import { TreeStep } from "./Steps/TreeStep";
import { KPIsStep } from "./Steps/KPIsStep";

export const EventGuide = ({ setCreatedEvent }) => {
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [treeInfoChanged, setTreeInfoChanged] = useState(false);
  const [importantNotesChanged, setImportantNotesChanged] = useState(false);

  const renderStep = () => {
    switch (currentStepNumber) {
      case 1:
        return (
          <ContextStep
            setTreeInfoChanged={setTreeInfoChanged}
            setCurrentStepNumber={setCurrentStepNumber}
          />
        );
      case 2:
        return (
          <TreeStep
            setImportantNotesChanged={setImportantNotesChanged}
            setTreeInfoChanged={setTreeInfoChanged}
            treeInfoChanged={treeInfoChanged}
            setCurrentStepNumber={setCurrentStepNumber}
          />
        );
      case 3:
        return (
          <KPIsStep
            setCreatedEvent={setCreatedEvent}
            importantNotesChanged={importantNotesChanged}
            setImportantNotesChanged={setImportantNotesChanged}
            treeInfoChanged={treeInfoChanged}
            setCurrentStepNumber={setCurrentStepNumber}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <Sidebar
        setCreatedEvent={setCreatedEvent}
        currentStepNumber={currentStepNumber}
        setTreeInfoChanged={setTreeInfoChanged}
        setCurrentStepNumber={setCurrentStepNumber}
      />
      <Box
        sx={{
          flexGrow: 1,
          height: "100%",
          backgroundColor: "#f3f3f3",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {renderStep()}
      </Box>
    </Box>
  );
};
