import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./reducers/store";
import "./index.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthProvider from "./services/auth";
import "./firebase";
import App from "./App";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Subscribe from "./pages/Subscribe";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import PricingPlans from "./pages/PricingPlans";
import Assessment from "./pages/Assessment";

const root = ReactDOM.createRoot(document.getElementById("root"));
const searchParams = new URLSearchParams(window.location.search);
const eventID = searchParams.get("eventID");
const label = searchParams.get("label");

root.render(
  eventID && label ? <Assessment eventID={eventID} label={label} /> :
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/subscribe" element={<PricingPlans />} />
          </Routes>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer style={{ width: "25%", maxWidth: "50vw" }} />
    </Provider>
);
