import { setEventData } from "../reducers/treeMapSlice";
import { useDispatch, useSelector } from "react-redux";

export const useTreeStream = () => {
  const dispatch = useDispatch();
  const eventData = useSelector((state) => state.treeMap.eventData);
  const parsedResponse = (res) => {
    const validateJSON = (text) => {
      try {
        return JSON.parse(text);
      } catch (error) {
        return;
      }
    };
    let stack = [];
    const brackets = {
      '"': '"',
      "[": "]",
      "{": "}",
    };
    for (const char of res) {
      let lastBracket = stack.slice(-1);
      if (brackets[char]) {
        if (char == '"' && lastBracket == '"') {
          stack.pop();
        } else stack.push(brackets[char]);
      } else {
        if (char == lastBracket) {
          stack.pop();
        }
      }
    }
    return validateJSON(`${res} ${stack.reverse().join("")}`);
  };
  let responseData = "",
    newBranch = "";
  const format_storeChunk = (chunk) => {
    responseData += chunk;
    parsedResponse(responseData) &&
      dispatch(setEventData(parsedResponse(responseData)));
  };
  const addNewBranchToEventData = (chunk) => {
    newBranch += chunk;
    parsedResponse(newBranch) &&
      dispatch(
        setEventData(
          eventData?.Pitfalls
            ? {
                Pitfalls: [parsedResponse(newBranch), ...eventData.Pitfalls],
              }
            : {
                name: eventData.name,
                children: [parsedResponse(newBranch), ...eventData.children],
              }
        )
      );
  };

  return { format_storeChunk, addNewBranchToEventData };
};
