import React, { useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import {
  createStripeSession,
  createStripeFreeTrial,
  getUserData,
} from "../../services/api-service";
import { notify } from "../../reducers/treeMapSlice";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../services/auth";
import { setUserData } from "../../reducers/treeMapSlice";
import { useDispatch } from "react-redux";

const PriceCard = ({ hadFreeTrial, plan, setClientSecret }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { token } = useAuth();
  const dispatch = useDispatch();

  const getCLientSecret = async () => {
    try {
      setLoading(true);
      return await createStripeSession(plan.default_price).then((res) => {
        setClientSecret(res.data?.url?.client_secret);
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 400)
        notify(error.response?.data?.error, "info");
    } finally {
      setLoading(false);
    }
  };

  const createFreeTrial = async () => {
    setLoading(true);
    try {
      return await createStripeFreeTrial(plan.default_price).then((res) => {
        setLoading(false);
        if (res?.status === 200) {
          getUserData(token).then((result) => {
            dispatch(setUserData(result?.user));
            localStorage.setItem("accessToken", result?.token);
            notify(res?.data?.message, "success");
            navigate("/");
          });
        }
      });
    } catch (error) {
      setLoading(false);
      if (error?.response?.status === 400) {
        notify(error.response?.data?.error, "success");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        border: "2px solid #ececec",
        borderRadius: "10px",
        padding: "0 10px",
        paddingBottom: "50px",
        boxSizing: "border-box",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "1px 1px 1px rgba(0, 0, 0, 0.05)",
        color: "#313131",
        "&:hover": {
          border: "2px solid #1d77b7",
          boxShadow: "none",
        },
      }}
    >
      <Box
        sx={{
          fontSize: "18px",
          fontFamily: "ClashDisplay-medium",
          padding: "20px 0",
          width: "100%",
          textAlign: "center",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          borderBottom: "1px solid #d9d9d9",
        }}
      >
        {plan?.name}
      </Box>
      <Box
        sx={{
          fontSize: "14px",
          color: "#313131",
          padding: "10px 0",
          display: "flex",
          alignItems: "end",
        }}
      >
        <span style={{ alignSelf: "start", marginTop: "11px" }}>$</span>
        <span style={{ fontSize: "50px" }}>{plan?.metadata?.amount / 100}</span>
        <span style={{ marginBottom: "10px" }}>/ Month</span>
      </Box>
      <Box sx={{ fontSize: "14px", mb: "15px" }}>
        {plan?.features?.map((el, i) => (
          <p key={i}>{el?.name}</p>
        ))}
      </Box>
      <Button
        variant="contained"
        sx={{
          position: "absolute",
          bottom: 5,
          height: 40,
          borderRadius: "30px",
          fontSize: "16px",
          fontFamily: "ClashDisplay-medium",
          textTransform: "none",
          width: "80%",
          backgroundColor: "#1D77B7",
          color: "white",
          boxShadow: "none",
          marginBottom: "20px",
          "&:hover": {
            background: "#0f639e",
            boxShadow: "none",
          },
        }}
        onClick={
          !loading ? (hadFreeTrial ? getCLientSecret : createFreeTrial) : null
        }
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : hadFreeTrial ? (
          "Subscribe"
        ) : (
          "Get Started Free"
        )}
      </Button>
    </Box>
  );
};

export default PriceCard;
