import "./App.css";
import { useSelector } from "react-redux";
import Dashboard from "./pages/Dashboard";
import Loader from "./components/Loader/Loader";
import ErrorBoundary from "./ErrorBoundary";

function App() {
  const userData = useSelector((state) => state.treeMap.userData);
  return (
    <ErrorBoundary>
      <div className="app">
        {!userData ? <Loader /> : <Dashboard />}
      </div>
    </ErrorBoundary>
  );
}

export default App;