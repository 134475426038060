import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Menu,
  MenuItem,
  Box,
  TextField,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";
import "./Header.css";
import {
  notify,
  setUserDataLoading,
  setVisualizationType,
  eventTitleUpdate,
  setSelectedUpdateTreeId,
  addShareLink,
} from "../../reducers/treeMapSlice";
import { gotoCustomerPortal, signOut } from "../../services/api-service";
import { ReactComponent as DownloadTree } from "../../assets/images/icons/downloadTree.svg";
import { ReactComponent as VisualIcon } from "../../assets/images/icons/visualIcon.svg";
import { ReactComponent as ShareIcon } from "../../assets/images/icons/share.svg";
import { ReactComponent as AsanaIcon } from "../../assets/images/icons/asana.svg";
import { toPng, toSvg, toJpeg } from "html-to-image";
import Logo from "../../assets/images/logo.svg";
import { jsPDF } from "jspdf";
import { IsMobile } from "../../utils/deviceType";
import { getAnalytics, logEvent } from "firebase/analytics";
import TextEntryModal from "../TextEntryModal/TextEntryModal";
import AsanaWorkspacesModal from "./AsanaWorkspacesModal";

export default function Header({ showManageEvent, setShowManageEvent }) {
  const dispatch = useDispatch();
  const selectedTreeId = useSelector((state) => state.treeMap.selectedTreeId);
  const userData = useSelector((state) => state.treeMap.userData);
  const eventGenerating = useSelector((state) => state.treeMap.eventGenerating);
  const visualizationType = useSelector(
    (state) => state.treeMap.visualizationType
  );
  const selectedEventData = useSelector((state) => state.treeMap.eventData);
  const eventRatings = useSelector((state) => state.treeMap.eventRatings);
  const selectedEvent = useSelector((state) => state.treeMap.eventFullData);
  const eventLoading = useSelector((state) => state.treeMap.loading);
  const [titleDisabled, setTitleDisabled] = useState(true);
  const [title, setTitle] = useState(null);
  const [treeLoaded, setTreeLoaded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [visualizeAnchorEl, setVisualizeAnchorEl] = useState(null);
  const [newEventAnchorEl, setNewEventAnchorEl] = useState(null);
  const [shareLinkOpen, setShareLinkOpen] = useState(false);
  const [asanaWorkspaces, setAsanaWorkspaces] = useState(false);
  const open = Boolean(anchorEl);
  const openDownload = Boolean(downloadAnchorEl);
  const openVisualize = Boolean(visualizeAnchorEl);
  const openNewEvent = Boolean(newEventAnchorEl);
  const isMobile = IsMobile();
  const analytics = getAnalytics();

  useEffect(() => {
    if (selectedEvent && !eventLoading) {
      setTitle(selectedEvent.title);
    } else {
      setTitle(null);
    }
  }, [selectedEvent, eventLoading]);

  const handleTitleFocusChange = () => {
    setTitleDisabled(true);
    (async () => {
      if (title && title !== "") {
        if (title === selectedEvent.title) {
          setTitleDisabled(true);
          return;
        }
        dispatch(
          eventTitleUpdate(
            selectedTreeId,
            title,
            selectedEvent.employee_name ?? title
          )
        );
      } else {
        setTitleDisabled(false);
      }
    })();
  };

  const onAccountClick = async () => {
    dispatch(setUserDataLoading(true));
    const response = await gotoCustomerPortal();
    if (response?.error) {
      notify(response.error.code, "error");
    } else {
      if (response?.url) {
        window.location.href = response?.url;
      }
    }
    setTimeout(() => {
      dispatch(setUserDataLoading(false));
    }, 1000);
  };

  const HeaderMenuIcon = ({ label, onClick, ...props }) => (
    <MenuItem sx={{ fontSize: "14px" }} onClick={onClick} {...props}>
      {label}
    </MenuItem>
  );

  const formatToMethodMap = {
    svg: toSvg,
    png: toPng,
    jpg: toJpeg,
  };

  const downloadTree = async (format) => {
    logEvent(analytics, `Tree Download Clicked!`, {
      user: userData._id,
    });

    setDownloadAnchorEl(null);
    const clonedTree = document
      .getElementById(visualizationType === 1 ? "currentList" : "currentTree")
      .cloneNode(true);
    const tempDiv = document.createElement("div");
    tempDiv.style.cssText = "position: relative; background: white";
    const newImg = document.createElement("img");
    newImg.style.cssText = `position: absolute;
    top: ${visualizationType === 2 && "20px"};
    right: ${visualizationType === 1 && "20px"};
    bottom: ${visualizationType === 1 && "40px"};
    left: ${visualizationType === 2 && "20px"};
    width: 25%`;
    tempDiv.appendChild(clonedTree);

    const imgBlob = await fetch(Logo).then((response) => response.blob());
    const imgBlobUrl = URL.createObjectURL(imgBlob);
    newImg.src = imgBlobUrl;

    tempDiv.appendChild(newImg);
    await new Promise((resolve) => setTimeout(resolve, 500));
    try {
      tempDiv.appendChild(newImg);
      document.body.appendChild(tempDiv);
      if (format === "pdf") {
        const pdf = new jsPDF("p", "mm", "a4");
        await toJpeg(tempDiv);
        const imgData = await toJpeg(tempDiv);
        const desiredHeightInMM = pdf.internal.pageSize.height;
        const aspectRatio = tempDiv.clientWidth / tempDiv.clientHeight;
        const desiredWidthInMM = desiredHeightInMM * aspectRatio;
        const xPos = (pdf.internal.pageSize.width - desiredWidthInMM) / 2;
        const yPos = 0;

        pdf.addImage(
          imgData,
          "JPEG",
          xPos,
          yPos,
          desiredWidthInMM,
          desiredHeightInMM
        );
        pdf.save("exported-tree.pdf");
        return;
      } else if (format === "txt") {
        const formatItem = (item, level = 0) => {
          let output = "";
          if (level === 0) {
            output += `**${item.name}** ${
              document.getElementById(item.name)?.querySelector("div span")
                ? document.getElementById(item.name).querySelector("div span")
                    ?.innerHTML
                : ""
            } \n`;
          } else if (level === 1) {
            output += `*${item.name}* ${
              document.getElementById(item.name)?.querySelector("div span")
                ? document.getElementById(item.name).querySelector("div span")
                    ?.innerHTML
                : ""
            } \n`;
          } else {
            output += `-${item.name}${
              eventRatings.find((el) => el.node_name == item.name && el.note)
                ? " (Note:" +
                  eventRatings.find((el) => el.node_name == item.name).note +
                  ")"
                : ""
            }${
              eventRatings.find(
                (el) => el.node_name == item.name && el.rate > 0
              )
                ? " (Rate:" +
                  eventRatings.find((el) => el.node_name == item.name).rate +
                  ")"
                : ""
            }\n`;
          }
          if (item.children && item.children.length > 0) {
            item.children.forEach((child) => {
              output += formatItem(child, level + 1);
            });
          }
          return output;
        };

        const eventChildren =
          selectedEventData?.Pitfalls || selectedEventData.children;

        const formattedText = eventChildren
          .map((pitfall) => formatItem(pitfall))
          .join("\n");

        const element = document.createElement("a");
        const file = new Blob([formattedText], {
          type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "exported-tree.txt";
        document.body.appendChild(element);
        element.click();
        return;
      }
      await formatToMethodMap[format](tempDiv);
      const dataUrl = await formatToMethodMap[format](tempDiv);
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = `exported-tree.${format}`;
      link.click();
    } catch (error) {
      console.error(`Error exporting ${format}:`, error);
    } finally {
      URL.revokeObjectURL(imgBlobUrl);
      document.body.removeChild(tempDiv);
    }
  };

  useEffect(() => {
    if (treeLoaded) {
      setTreeLoaded(selectedEventData && !eventLoading);
    } else {
      const timeoutId = setTimeout(() => {
        setTreeLoaded(selectedEventData && !eventLoading);
      }, 100);
      return () => clearTimeout(timeoutId);
    }
  }, [selectedEventData, eventLoading]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("code")) {
      setAsanaWorkspaces(true);
    }
  }, []);

  const handleAddShareLink = (label) => {
    dispatch(addShareLink(label));
  };

  return (
    <div className="header-container">
      <div>
        <Button
          className="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={(event) => setAnchorEl(event.currentTarget)}
          startIcon={
            <div className="username">
              {userData?.email ? userData?.email.charAt(0) : null}
            </div>
          }
        ></Button>
        <Menu
          className="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          elevation={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: "100px",
              maxWidth: "100px",
              borderRadius: "3px",
              margin: "2px 0px 2px",
              border: "1px solid #dde0e4",
              boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <HeaderMenuIcon
            label="Privacy"
            onClick={() =>
              window.open("https://www.antethink.com/privacy-policy", "_blank")
            }
          />
          <HeaderMenuIcon label="Account" onClick={() => onAccountClick()} />
          <HeaderMenuIcon label="Sign-out" onClick={() => signOut()} />
        </Menu>
      </div>
      {/* Load title of the loaded d3 chart */}
      {!showManageEvent && (
        <Box style={{ display: "flex", alignItems: "center" }}>
          {!titleDisabled ? (
            <TextField
              disabled={titleDisabled || eventGenerating}
              inputProps={{ style: { fontFamily: "ClashDisplay-regular" } }}
              className="tree-title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => handleTitleFocusChange()}
              autoFocus={true}
            />
          ) : (
            <Tooltip title="Edit Title" arrow>
              <Box
                className="tree-title"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                onClick={() => setTitleDisabled(false)}
              >
                {title}
              </Box>
            </Tooltip>
          )}
          {!eventGenerating && (
            <>
              <div>
                {/* asana button */}
                <Tooltip title="Export event to Asana project" arrow>
                  <IconButton
                    className="exportToAsana"
                    onClick={() => {
                      let url = new URL(window.location.href).searchParams,
                        stateObject = JSON.stringify({
                          eventId: url.get("eventId"),
                          page: url.get("page"),
                        });
                      window.open(
                        `https://app.asana.com/-/oauth_authorize?client_id=1207806128873782&redirect_uri=${window.location.origin}/&response_type=code&scope=default&state=${stateObject}`,
                        "_self"
                      );
                    }}
                    sx={{
                      padding: "0px",
                      ml: "5px",
                      visibility: treeLoaded ? "visible" : "hidden",
                      opacity: treeLoaded ? 1 : 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <AsanaIcon style={{ marginRight: -3 }} />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Share Event" arrow>
                  <IconButton
                    className="shareEvent"
                    onClick={() => setShareLinkOpen(true)}
                    sx={{
                      padding: "0px",
                      ml: "5px",
                      visibility: treeLoaded ? "visible" : "hidden",
                      opacity: treeLoaded ? 1 : 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <Tooltip title="Download Event" arrow>
                  <IconButton
                    className="downloadTree"
                    onClick={(event) =>
                      setDownloadAnchorEl(event.currentTarget)
                    }
                    sx={{
                      padding: "0px",
                      minWidth: "5px",
                      margin: "0px 8px",
                      visibility: treeLoaded ? "visible" : "hidden",
                      opacity: treeLoaded ? 1 : 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <DownloadTree />
                  </IconButton>
                </Tooltip>
                <Menu
                  className="user-menu"
                  anchorEl={downloadAnchorEl}
                  open={openDownload}
                  onClose={() => setDownloadAnchorEl(null)}
                  elevation={0}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: {
                      minWidth: "100px",
                      maxWidth: "100px",
                      borderRadius: "3px",
                      margin: "2px 0px 2px",
                      border: "1px solid #dde0e4",
                      boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                      WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <HeaderMenuIcon
                    label="Save as SVG"
                    onClick={() => downloadTree("svg")}
                    style={{ fontSize: "12px" }}
                  />
                  <HeaderMenuIcon
                    label="Save as PNG"
                    onClick={() => downloadTree("png")}
                    style={{ fontSize: "12px" }}
                  />
                  <HeaderMenuIcon
                    label="Save as JPG"
                    onClick={() => downloadTree("jpg")}
                    style={{ fontSize: "12px" }}
                  />
                  <HeaderMenuIcon
                    label="Save as PDF"
                    onClick={() => downloadTree("pdf")}
                    style={{ fontSize: "12px" }}
                  />
                  {visualizationType === 1 && (
                    <HeaderMenuIcon
                      label="Save as TXT"
                      onClick={() => downloadTree("txt")}
                      style={{ fontSize: "12px" }}
                    />
                  )}
                </Menu>
              </div>
              <div>
                <Tooltip title="Change Visualization" arrow>
                  <IconButton
                    className="visualizeType"
                    onClick={(event) =>
                      setVisualizeAnchorEl(event.currentTarget)
                    }
                    sx={{
                      padding: "0px",
                      visibility: treeLoaded ? "visible" : "hidden",
                      opacity: treeLoaded ? 1 : 0,
                      backgroundColor: "transparent",
                    }}
                  >
                    <VisualIcon />
                  </IconButton>
                </Tooltip>
                <Menu
                  className="user-menu"
                  anchorEl={visualizeAnchorEl}
                  open={openVisualize}
                  onClose={() => setVisualizeAnchorEl(null)}
                  elevation={0}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: {
                      minWidth: "120px",
                      maxWidth: "120px",
                      borderRadius: "3px",
                      margin: "2px 0px 2px",
                      border: "1px solid #dde0e4",
                      boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                      WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
                    },
                  }}
                >
                  <HeaderMenuIcon
                    label="Visualize as Tree"
                    onClick={() =>
                      dispatch(
                        setVisualizationType(2),
                        setVisualizeAnchorEl(null)
                      )
                    }
                    style={{ fontSize: "12px" }}
                  />
                  <HeaderMenuIcon
                    label="Visualize as List"
                    onClick={() =>
                      dispatch(
                        setVisualizationType(1),
                        setVisualizeAnchorEl(null)
                      )
                    }
                    style={{ fontSize: "12px" }}
                  />
                </Menu>
              </div>
            </>
          )}
        </Box>
      )}
      <div>
        <Box
          className="add-tree"
          onClick={(event) => setNewEventAnchorEl(event.currentTarget)}
        >
          <AddCircleIcon color="primary"></AddCircleIcon>
          {!isMobile && <Box className="new-title">Create new flow</Box>}
        </Box>
        <Menu
          className="user-menu"
          anchorEl={newEventAnchorEl}
          open={openNewEvent}
          onClose={() => setNewEventAnchorEl(null)}
          elevation={0}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              minWidth: "100px",
              maxWidth: "100px",
              borderRadius: "3px",
              margin: "2px 0px 2px",
              border: "1px solid #dde0e4",
              boxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
              WebkitBoxShadow: "2px 1px 2px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <HeaderMenuIcon
            label="Role"
            onClick={() => (
              dispatch(setSelectedUpdateTreeId(null)),
              setShowManageEvent("role"),
              setNewEventAnchorEl(null)
            )}
            style={{ fontSize: "12px" }}
          />
          <HeaderMenuIcon
            label="Situation"
            onClick={() => (
              dispatch(setSelectedUpdateTreeId(null)),
              setShowManageEvent("situation"),
              setNewEventAnchorEl(null)
            )}
            style={{ fontSize: "12px" }}
          />
        </Menu>
      </div>
      <TextEntryModal
        header="Create Share Link"
        inputPlaceholder="Enter a name or label for this link"
        buttonText="Create"
        textEntryModalOpened={shareLinkOpen}
        setTextEntryModalOpened={setShareLinkOpen}
        action={handleAddShareLink}
      />
      {asanaWorkspaces && (
        <AsanaWorkspacesModal
          asanaWorkspaces={asanaWorkspaces}
          setAsanaWorkspaces={setAsanaWorkspaces}
        />
      )}
    </div>
  );
}
