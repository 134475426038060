import { Box } from "@mui/material";
import { ReactComponent as Correct } from "../../assets/images/icons/correct.svg";
import { useSelector } from "react-redux";

export const StepItem = ({
  title,
  icon,
  description,
  stepIndex,
  currentStepNumber,
  setCurrentStepNumber,
  setTreeInfoChanged,
}) => {
  const lastCompletedStep = useSelector(
    (state) => state.treeMap.lastCompletedStep
  );
  const isCurrentStep = currentStepNumber === stepIndex;
  const canActivateStep = lastCompletedStep + 1 >= stepIndex;
  const isStepCompleted = lastCompletedStep >= stepIndex;
  return (
    <Box
      onClick={() =>
        canActivateStep &&
        (setCurrentStepNumber(stepIndex), setTreeInfoChanged(false))
      }
      sx={{
        display: "flex",
        cursor: canActivateStep ? "pointer" : "default",
        my: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexShrink: 0,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "100%",
          border: `1px solid ${isStepCompleted || isCurrentStep ? "#1D77B7" : canActivateStep ? "black" : "gray"}`,
          width: 40,
          height: 40,
          backgroundColor:
            isStepCompleted || isCurrentStep ? "#1D77B7" : "white",
        }}
      >
        {isStepCompleted ? (
          <Correct width="50%" />
        ) : (
          icon(
            isStepCompleted || isCurrentStep
              ? "white"
              : canActivateStep
                ? "black"
                : "gray"
          )
        )}
      </Box>
      <Box sx={{ ml: 2 }}>
        <Box color="gray" fontSize="12px">
          Step {stepIndex}
        </Box>
        <Box
          fontSize="18px"
          fontWeight={isCurrentStep && "600"}
          color={isCurrentStep ? "#1D77B7" : canActivateStep ? "black" : "gray"}
        >
          {title}
        </Box>
        {isCurrentStep && (
          <Box color="gray" fontSize="14px">
            {description}
          </Box>
        )}
      </Box>
    </Box>
  );
};
