import React from "react";
import "./Loader.css";
import { Box } from "@mui/material";
import { ReactComponent as BrandLogo } from "../../assets/images/logo.svg";
import { LinearProgress } from "@mui/material";

const Loader = (props) => {
  return (
    <Box className="progress-container" sx={props?.extraStyles}>
      {props.text || (
        <Box>
          <BrandLogo style={{ maxWidth: "50vw", marginBottom: "5px" }} />
        </Box>
      )}
      <LinearProgress
        variant="indeterminate"
        sx={{
          marginTop: "3px",
          width: "inherit",
          maxWidth: "50vw",
          backgroundColor: "#a4a4a450",
          borderRadius: "2px",
        }}
      />
    </Box>
  );
};

export default Loader;
