import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as ExpandMore } from "../../assets/images/icons/downIcon.svg";
import { ListItemButton, List, ListItemText, Collapse } from "@mui/material";
import { exportEventToAsana } from "../../services/api-service";
import { notify } from "../../reducers/treeMapSlice";
import { useSearchParams } from "react-router-dom";

export const AsanaWorkspace = ({ workspace, setTeamClicked }) => {
  const [open, setOpen] = useState(false),
    [searchParams, setSearchParams] = useSearchParams(),
    listRef = useRef(null),
    listItemStyles = {
      borderRadius: 2,
      "&:hover": {
        color: "#1D77B7",
        fontWeight: 900,
      },
    };

  useEffect(() => {
    if (listRef.current) {
      if (open) {
        listRef.current.style.height = `${listRef.current.scrollHeight}px`;
      } else {
        listRef.current.style.height = "0px";
      }
    }
  }, [open]);

  const exportEvent = async (workspaceId, teamId) => {
    setTimeout(() => {
      setTeamClicked(true);
    }, 200);
    await exportEventToAsana({
      eventID: JSON.parse(searchParams?.get("state")).eventId,
      workspaceId,
      teamId,
    }).then((res) => {
      if (res?.status === 200) {
        notify(
          "Event has been sent exported to Asana Successfully!",
          "success"
        );
      }
    });
  };

  return (
    <>
      <ListItemButton onClick={() => setOpen(!open)} sx={listItemStyles}>
        <ListItemText primary={workspace.name} />
        <ExpandMore
          style={{
            width: "25px",
            transform: open && "rotate(180deg)",
            transition: "all 0.3s",
          }}
        />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List ref={listRef} sx={{ transition: "height 0.3s" }} disablePadding>
          {open &&
            workspace.teams.map((team) => (
              <ListItemButton
                sx={{ ...listItemStyles, pl: 4 }}
                onClick={() => exportEvent(workspace.gid, team.gid)}
              >
                <ListItemText primary={team.name} />
              </ListItemButton>
            ))}
        </List>
      </Collapse>
    </>
  );
};
